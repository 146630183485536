<template>
	<div class="section_wrap pt-50 pb-80">
		<!-- 카르텔 리스트 -->
		<div class="thumbnail_wrap">
			<div class="container">
				<div class="row">
					<ul class="thumbnail_list">
						<template
							v-if="items_notify.length > 0"
						>
						<li
							v-for="(item, index) in items_notify"
							:key="'item_' + index"
						>
							<div class="circle_thumbnail_item">
								<a
									class=""
									@click="to(item)"
								>
									<div class="picture">
										<img
											v-if="item.sending_member_profle_nft_card_img_url"
											:src="item.sending_member_profle_nft_card_img_url"
										/>
										<img
											v-else
											:src="require('@/assets/image/@noimage.png')"
										/>
									</div>
									<div
										class="text_area"
										:class="{ off: item.fg != 'Y'}"
									>
										<strong>[{{  item.note_type_name }}] {{ item.note_title}}</strong>
										<p class="textzone">
											{{ item.note_contents }}
										</p>
										<p>{{ item.sending_member_nickname }} <span>{{ item.sendiingdate}}</span></p>
									</div>
								</a>
							</div>
						</li>
						</template>
						<li
							v-else
						>

							<div
								class="list_none"
								style="overflow: hidden; height: 100%;"
								:style="$route.params.code ? 'padding-top: 100%' : ''"
							>
								<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
								<span>{{  $language.common.NotFoundRegisteredNotice }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- //카르텔 리스트 -->

	</div>
</template>

<script>
	export default {
		name: 'mafia118'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.notify
					, title: this.$language.common.notify
					, type: 'mypage'
				}
				, items_notify: []
				, item_search: {
					page: 1
					, list_cnt: 10
				}
			}
		}
		, methods:{
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_notify_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
						}
						, type: true
					})

					if(result.success){
						this.items_notify = result.data.member_note_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log('mafia118 getData ', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, to: function(item){
				let r = item.relay_info
				if(r){
					r = JSON.parse(r)
					item.idx = r.cartl_number
					item.code = r.board_type_code
					item.b_id = r.board_number
					item.bbs_id = r.bulletin_number
					item.vote_board_number = r.vote_board_number
					item.vote_number = r.vote_number
				}

				switch (item.note_type_code.replace('\r', '')){
					case 'AL00100001':
						this.$emit('to', { name: 'notice'})
						break
					case 'AL00100002':
						this.$emit('to', { name: 'mafia058', params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}})
						break
					case 'AL00100003':
						this.$emit('to', { name: 'staking'})
						break
					case 'AL00100004':
						this.$emit('to', { name: 'mafia049', params: {idx: item.idx}})
						break
					case 'AL00100005':
						this.$emit('to', { name: 'mafia049', params: {idx: item.idx}})
						break
					case 'AL00100006':
						this.$emit('to', { name: 'mafia058', params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}})
						break
					case 'AL00100007':
						this.$emit('to', { name: 'mafia058', params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}})
						break
					case 'AL00100008':
						this.$emit('to', { name: 'mafia058', params: {idx: item.idx, code: item.code, b_id: item.b_id, bbs_id: item.bbs_id}})
						break
					case 'AL00100009':
						this.$emit('to', { name: 'mafia049', params: {idx: item.idx}})
						break
					case 'AL00100016':
						this.$bus.$emit('to', { name: 'CartelVotingInfo', params: { idx: item.idx, v_id: item.vote_board_number, e_id: item.vote_number, from: this.$route.fullPath }})
						break
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.getData()

			try{
				this.$common.inAppWebviewCommunity('android', 'removeBadge');
			}catch (e) {
				console.log(e)
			}
		}
	}
</script>
<style>
	.off {opacity: 1}
</style>